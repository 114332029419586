<template>
	<div class="a_r_detail">
		<div class="warpBox">
			<div class="tformWarp bggray" v-if="htmlDatas.deviceId">
				<div class="topName">
					<span class="name">{{htmlDatas.detailTitle}}</span>
					<span class="lbs" v-if="htmlDatas.isSmartDevice=='Y'">智能</span>
					<span class="lbs" v-if="htmlDatas.deviceStatusStr" :class="htmlDatas.deviceStatus">{{htmlDatas.deviceStatusStr}}</span>
				</div>
				<div class="lbsWarp" v-if="htmlDatas.tagList">
					<!--<span class="itlb">设备标签</span>-->
					<span class="itlb" v-for="(lbName,dex) in htmlDatas.tagList" :key='dex'>{{lbName}}</span>
				</div>
				<div class="txForm" >
					<TForm ref="deviceFrom" :model="htmlDatas" :formlist="devicelist" label-width='88px'></TForm>
				</div>
			</div>

			<div class="tformWarp bggray">
				<div class="topName"><span class="name">告警信息</span></div>
				<div class="txForm">
					<TForm ref="alarmFrom" :model="htmlDatas" :formlist="alarmlist" label-width='88px'>
						<template slot="alarmImgs" slot-scope="scope">
							<TImgVideo :model='htmlDatas[scope.current.keys]||[]' itWidth='120px' itHeight='120px' /> 
						</template>
					</TForm>
				</div>
			</div>
			<div class="tformWarp bgpink" :class="{bgNomral:htmlDatas.alarmStatus=='normal'}">
				<div class="topName"><span class="name">恢复信息</span></div>
				<div class="txForm">
					<TForm ref="recoverFrom" :model="htmlDatas" :formlist="recoverlist" label-width='88px'>
						<template slot="normalImgs" slot-scope="scope">
							<TImgVideo :model='htmlDatas[scope.current.keys]||[]' itWidth='120px' itHeight='120px' /> 
						</template>
					</TForm>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	import { findAlarmDetail } from "@/api/ruge/vlink/alarm/alarm";
	import { deepClone } from "@/utils/utils.js";
	import { dateFormat } from "@/filters/index.js";
	import { findTsl } from "@/api/ruge/vlink/product/product";
	import handsRlink from "@/mixins/handsRlink.js";
	import {getLoopUpItem} from "@/api/business/base/tenant/device";
	import TImgVideo from '@/components/YTable/TImgVideo.vue';
	const itFlex = 8;
	export default {
		mixins: [handsRlink],
		components: {
			TForm,TImgVideo
		},
		props: {},
		watch: {},
		data() {
			return {
				htmlAlarmId:'',
				htmlDatas: {},
				deviceNamesObj:{},
				devicelist: [
					{name: '所属项目:',keys: 'projectName',value: '',type: 'text',flex:itFlex},
					{name: '设备编码:',keys: 'factoryCode',value: '',type: 'text',flex:itFlex},
					{name: '设备位置:',keys: 'descName',value: '',type: 'text',flex:itFlex},
//					{name: '产品标识码:',keys: 'productKey',value: '',type: 'text',flex:itFlex},
//					{name: '设备标识码:',keys: 'rlinkDeviceName',value: '',type: 'text',flex:itFlex},
					{name: '所属系统:',keys: 'categoryName',value: '',type: 'text',flex:itFlex},
					{name: '责任部门:',keys: 'dutyDeptName',value: '',type: 'text',flex:itFlex},
					{name: '责任人:',keys: 'repairPersonName',value: '',type: 'text',flex:itFlex},
					{name: '维修工时:',keys: 'workTime',value: '',type: 'text',flex:itFlex},
	  				{name: '更新时间:',keys: 'lastUpdateDateStr',value: '',type: 'text',flex:itFlex},
					{name: '创建时间:',keys: 'creationDateStr',value: '',type: 'text',flex:itFlex},
					{name: '设备类型:',keys: 'deviceTypeNameStr',value: '',type: 'text',flex:itFlex},
				],
				alarmlist: [
					{name: '告警ID:',keys: 'alarmUUID',value: 'A',type: 'text',flex: itFlex},
					{name: '告警时间:',keys: 'alarmTimestampStr',value: '',type: 'text',flex: itFlex},
					{name: '告警状态:',keys: 'alarmStatusStr',value: '',type: 'text',flex: itFlex},
					{name: '告警级别:',keys: 'alarmLevelStr',value: '',type: 'text',flex: itFlex},
					{name: '告警类型:',keys: 'alarmTypeStr',value: '',type: 'text',flex: itFlex},
					{name: '告警内容:',keys: 'alarmContent',value: '',type: 'text',flex: itFlex},
					{name: '设备参数:',keys: 'propertyDescription',value: '',type: 'text',flex: itFlex},
					{name: '告警值:',keys: 'alarmValueUnit',value: '',type: 'text',flex: itFlex},
					{name: '告警图片',keys: 'alarmImgList',value: [],type: 'slot',slotName: "alarmImgs",flex:24},
				],
				recoverlist: [
					{name: '恢复状态',keys: 'normalStatusStr',value: '',type: 'text',flex: 8},
					{name: '恢复时间',keys: 'normalTimestampStr',value: '',type: 'text',flex: 16},
					{name: '恢复描述',keys: 'normalContent',value: '',type: 'text',flex: 8},
					{name: '恢复值',keys: 'normalValueUnit',value: '',type: 'text',flex: 16},
					{name: '恢复图片',keys: 'normalImgList',value: [],type: 'slot',slotName: "normalImgs",flex:24},
				],

			}
		},
		created() {
			this.initDeviceTypes();
			this.resetTFrom();
			const {
				rowId
			} = this.$route.query;
			if(rowId){
				this.htmlAlarmId=rowId;
				this.initHtmlData();
			}
			
		},
		methods: {
			initDeviceTypes(){
				getLoopUpItem({ classifyCode: "DEVICE_TYPE" }).then((res) => {
      				res.map(it=>this.deviceNamesObj[it.itemCode]=it.itemName);
  				});
			},
			resetTFrom() {
				for(let item of this.devicelist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
			},
			async initHtmlData(){
				
				let alarminfo=await this.getAlarmDetailById(this.htmlAlarmId);
				if(alarminfo.alarmStatus=='alarm'){
					//告警的时候没有恢复信息  设为- - 
					alarminfo.normalStatusStr='--';
//					alarminfo.alarmTimestampStr='--';
					alarminfo.normalContent='--';
					alarminfo.normalValue='--';
					alarminfo.normalTimestampStr='--';
				}else{
					alarminfo.normalStatusStr='恢复';
				}
				if(!alarminfo.deviceId){
					this.htmlDatas={...alarminfo};
					this.$message({
						message: '未绑定设备',
						type: 'info'
					})
					return;
				}
				
				let infoBasics=await this.getDeviceBasicsInfo(alarminfo.deviceId);
				if(infoBasics.deviceType) infoBasics.deviceTypeNameStr=this.deviceNamesObj[infoBasics.deviceType]
				let params={
				    "productKey" : alarminfo.productKey,
				    "rlinkDeviceName" : alarminfo.deviceName,
				}
//				let infoSarmt=await this.getDeviceSarmtInfo(params);
				alarminfo.detailTitle=infoBasics.deviceName;
				this.htmlDatas={...alarminfo,...infoBasics,};
				console.log('htmlDatas',JSON.parse(JSON.stringify(this.htmlDatas)))
			},
			

		},

	};
</script>
<style lang="scss">
	.a_r_detail {
		height: calc(100vh - 56px);
		overflow-y: auto;
		padding: 20px;
		.warpBox {
			background: white;
			padding: 36px 24px;
		}
		.tformWarp {
			border: 1px solid #DCDFE6;
			padding: 20px;
			border-radius: 3px;
			padding-bottom: 8PX;
			margin-bottom: 32px;
		}
		.tformWarp:last-child {
			margin-bottom: 0;
		}
		.topName {
			.name {
				font-size: 16px;
				font-weight: bold;
				color: #303133;
				line-height: 26px;
			}
			.lbs {
				margin: 0 6px;
				padding: 2px 5px;
				font-size: 12px;
				color: #3880FF;
				border-radius: 2px;
				border: 1px solid #3880FF;
				position: relative;
				top: -2px;
			}
			.lbs.ONLINE{
				color: #67c23a;
				border: 1px solid #67c23a;
			}
			.lbs.OFFLINE{
				color: #f56c6c;
				border: 1px solid #f56c6c;
			}
		}
		.lbsWarp {
			margin-top: 8px;
			margin-bottom: 4px;
			.itlb {
				background: #ECF5FF;
				border-radius: 2px;
				font-size: 12px;
				color: #409EFF;
				padding: 5px 4px;
				margin-right: 6px;
			}
		}
		.bggray {
			background-color: #f8f8f8;
		}
		.bgpink {
			background-color: #fef0f0;
		}
		.tformWarp.bgNomral{
			background-color: #f0f9eb;
		}
	}
</style>
<style>
	.txForm .Y-myFrom .el-form-item__label {
		color: #909399;
	}
</style>